<template>
    <div>
        <div
            class="fixed w-full h-[120%] pointer-events-none z-frame transition-colors duration-1000 overflow-hidden " :class="['text-v-' +colorType]">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="100%"
                 width="100%" style="">
                <rect x="0" y="0" height="100%" width="100%" stroke="currentColor" rx="40" stroke-width="50"
                      fill="transparent"></rect>
            </svg>
        </div>
        <div
            class="bottom-0 fixed w-full h-[120%] pointer-events-none z-frame transition-colors duration-1000 overflow-hidden " :class="['text-v-' +colorType]">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="100%"
                 width="100%">
                <rect x="0" y="0" height="100%" width="100%" stroke="currentColor" rx="40" stroke-width="50"
                      fill="transparent"></rect>
            </svg>
            <div class="footer-s">沪ICP备2023005511号</div>
        </div>
        <div v-show="show" data-v-7b93e9e1="" :class="['menu-modal show','bg-v-'+colorType]">
            <div data-v-7b93e9e1="" class="inner">
                <ul data-v-7b93e9e1="" class="list">
                    <li data-v-7b93e9e1=""><a data-v-7b93e9e1="" href="#keyvisual" class="item" @click="onShow('keyvisual')">
                        <div data-v-7b93e9e1="" class="en">Home</div>
                        <div data-v-7b93e9e1="" class="ja">首页</div>
                    </a></li>
                    <li data-v-7b93e9e1=""><a data-v-7b93e9e1="" href="#about" class="item" @click="onShow('about')">
                        <div data-v-7b93e9e1="" class="en">ABOUT</div>
                        <div data-v-7b93e9e1="" class="ja">关于</div>
                    </a></li>
                    <li data-v-7b93e9e1=""><a data-v-7b93e9e1="" href="#feature" class="item" @click="onShow('feature')">
                        <div data-v-7b93e9e1="" class="en"> FEATURE</div>
                        <div data-v-7b93e9e1="" class="ja">特色</div>
                    </a></li>
                    <li data-v-7b93e9e1=""><a data-v-7b93e9e1="" href="#contact" class="item" @click="onShow('contact')">
                        <div data-v-7b93e9e1="" class="en">CONTACT</div>
                        <div data-v-7b93e9e1="" class="ja">联系</div>
                    </a></li>
                </ul>
                <div data-v-7b93e9e1="" class="chara">
                    <div data-v-7a925bcb="" data-v-7b93e9e1="" class="popup active" style="--size: 100%;">
                        <div data-v-7a925bcb=""
                             class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                            <img data-v-7a925bcb="" src="../assets/image/text-circle-fbd2a2af.svg"
                                 class="animate-spin-slow"></div>
                        <div data-v-7a925bcb=""
                             class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center " :class="[isAmimate?'bg-100%':'bg-120%']"></div>
                        <div data-v-7a925bcb=""
                             class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                            <img data-v-7a925bcb="" :src="src"
                                 class="transition-all duration-500 ease-elastic w-full"
                                 :class="[isAmimate?'translate-y-35':'translate-y-50']"
                            ><!----></div>
                        <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                            <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="show" @click="changeShow"  data-v-7b93e9e1="" class="menu-button close">
            <div data-v-7b93e9e1="" class="icon"></div><div data-v-7b93e9e1="" class="label">CLOSE</div></button>
        <button v-else  @click="changeShow"  data-v-7b93e9e1="" class="menu-button menu">
            <div data-v-7b93e9e1="" class="icon"></div><div data-v-7b93e9e1="" class="label">MENU</div></button>
        <div data-v-7b93e9e1="" class="logo visible opacity-100">
            <img v-if="show" data-v-7b93e9e1=""
                 style="width:60px"
              class="transition-all duration-500 visible opacity-100"
              src="../assets/image/favicon.png">
        </div>
        <div data-v-c77cde0e="" data-v-b6b535b3="" class="main-contents">
            <section data-v-b6b535b3="" id="keyvisual" class="" v-show="(!isApp && type=='keyvisual') || isApp">
                <div data-v-6e09b85e="" data-v-b6b535b3="" class="relative w-full h-[calc(var(--vh)*110)] md:h-[110vh]"
                     style="--vh: 6.01px;">
                    <div data-v-6e09b85e="" class="logo show">
                        <div data-v-6e09b85e=""
                             class="text-text-gray font-special font-bold text-sm md:text-2xl tracking-widest mb-2">
                            领先的金融业营销及客户运营服务商
                        </div>
                        <p class="title" style="letter-spacing: 1px">
                            大境元游
                            <img src="../assets/image/favicon.png" class="" alt="">
                        </p>
                        <p><img src="../assets/image/dj.png" alt=""></p>
                    </div>
                    <div data-v-6e09b85e="" class="absolute inset-0 overflow-hidden h-[120%]">
                        <div data-v-6e09b85e="" class="relative h-[80%]">
                            <img data-v-6e09b85e="" src="../assets/image/character-f-1-335aec97.png"
                                 :class="['character f1 show','animate__animated animate__zoomIn']"><img
                            data-v-6e09b85e="" src="../assets/image/character-f-2-3976119c.png"
                            class="character f2 show animate__animated animate__zoomIn"><img
                            data-v-6e09b85e="" src="../assets/image/character-f-3-0c70b00e.png"
                            class="character f3 show animate__animated animate__zoomIn"><img
                            data-v-6e09b85e="" src="../assets/image/character-f-4-8045fe88.png"
                            class="character f4 show animate__animated animate__zoomIn"><img
                            data-v-6e09b85e="" src="../assets/image/character-m-1-fc1a7590.png"
                            class="character m1 show animate__animated animate__zoomInRight"><img
                            data-v-6e09b85e="" src="../assets/image/character-m-2-c586d85b.png"
                            class="character m2 show animate__animated animate__zoomInLeft"><img
                            data-v-6e09b85e="" src="../assets/image/character-m-3-ced20db2.png"
                            class="character m3 show animate__animated animate__fadeIn"><img
                            data-v-6e09b85e="" src="../assets/image/character-m-4-8779a946.png"
                            class="character m4 show animate__animated animate__zoomIn">
                        </div>
                    </div>
                </div>
            </section>
            <section data-v-b6b535b3="" id="about" v-show="(!isApp && type=='about') || isApp" class="md:flex md:max-w-5xl mt-24 mx-auto md:mt-72">
                <div data-v-b6b535b3="" class="md:w-5/12">
                    <div data-v-b6b535b3="" class="w-[70%] mx-auto rotate-0 mb-12 md:mb-36">
                        <div data-v-7a925bcb="" data-v-b6b535b3="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center "
                                 style="" :class="[isAmimate?'bg-100%':'bg-120%']"></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-f-2-3976119c.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                    <div data-v-b6b535b3="" class="w-[40%] mx-auto md:-translate-x-14 -rotate-15 mb-36 hidden md:block">
                        <div data-v-7a925bcb="" data-v-b6b535b3="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center" :class="[isAmimate?'bg-100%':'bg-120%']"
                            ></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-m-4-8779a946.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                    <div data-v-b6b535b3="" class="w-[50%] mx-auto md:translate-x-8 rotate-15 hidden md:block">
                        <div data-v-7a925bcb="" data-v-b6b535b3="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center" :class="[isAmimate?'bg-100%':'bg-120%']"
                            ></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-f-4-8045fe88.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate2?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-v-b6b535b3="" class="md:w-7/12 px-9 md:px-0">
                    <div data-v-b6b535b3="" style="font-size: 74.5833px;"><h2
                        class="tracking-widest3 leading-loose md:leading-loose font-bold"><span
                        class="underline underline-offset-[-12px] decoration-24 decoration-v-yellow" style="font-size: 52px;line-height: inherit">ABOUT<br>公司简介<br></span><span></span>
                    </h2></div>
                    <div data-v-b6b535b3="" class="md:text-xl tracking-wider leading-9 md:leading-9 fadein is-inview"
                         data-scroll-class="0.5">
                        <p data-v-b6b535b3="" class="my-10" style="text-align: left;text-indent: 2em">
                        上海大境元游数字科技有限公司成立于2022年，隶属于云睦加创投集团旗下，总部位于上海。</p>
                        <p data-v-b6b535b3="" class="my-10" style="text-align: left;text-indent: 2em">
                            是一家以会员营销服务为基础前端、客户运营为拓展后端的数字化科技服务公司。公司通过自建权益平台、微信小程序等，与互联网供应链及实体供货服务商深度合作，建立并发展起以客户权益为核心、B2C模式的会员营销管理体系，旨在为平台会员群体提供福利选择及受益保障。</p>
                        <p data-v-b6b535b3="" class="my-10" style="text-align: left;text-indent: 2em">
                            公司拥有独立的技术开发及运营团队，为平台会员权益服务提供保障与支持，同时以“市场为导向”和“研发为核心”的双核驱动组织架构，强化市场体系对客户服务、战略方向和资源规划的决策支撑能力。通过营销管理、研发管理、供应链管理等系统的高效运作，确保以客户满意度为导向的公司整体战略实施。</p></div>
                </div>
                <div data-v-b6b535b3="" class="md:hidden flex pt-32 overflow-hidden">
                    <div data-v-b6b535b3="" class="w-[40%] mx-auto -rotate-15">
                        <div data-v-7a925bcb="" data-v-b6b535b3="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center bg-120%"
                            ></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-m-4-8779a946.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                    <div data-v-b6b535b3="" class="w-[50%] mx-auto rotate-15">
                        <div data-v-7a925bcb="" data-v-b6b535b3="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center bg-120%"
                            ></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-f-4-8045fe88.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-v-b6b535b3="" id="feature" v-show="(!isApp && type=='feature') || isApp" class="mt-28 md:mt-28 max-w-4xl mx-auto">
                <div data-v-77d317c9="" class="price">
                    <div data-v-77d317c9="" class="w-[30%] -rotate-30 absolute left-3 md:left-6 top-8 md:top-28">
                        <div data-v-7a925bcb="" data-v-77d317c9="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div><!---->
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-m-3-ced20db2.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb="" class="rounded-full w-full h-full -translate-y-12"></div>
                            </div>
                        </div>
                    </div>
                    <div class="circle" data-v-77d317c9="">
                        <div class="inner" data-v-77d317c9="">
                            <div class="en fade-slide-up is-inview" data-scroll-class="0.5" data-v-77d317c9="">FEATURE
                            </div>
                            <div class="ja fadein is-inview" data-scroll-class="0.5" data-v-77d317c9="">特色</div>
                            <div class="text fadein is-inview" data-scroll-class="0.5" data-v-77d317c9="">
                                拉新促活服务商<br data-v-77d317c9="">数字产品集成供应商 <br>积分商城及客户运营服务商</div>
                            <div class="note md:block fadein is-inview" data-scroll-class="0.5"
                                 data-v-77d317c9="">※我们不卖服务，卖解决方案。<br data-v-77d317c9="">※我们不卖系统，卖运营服务。
                            </div>
                        </div>
                    </div>
                    <div data-v-77d317c9="" class="absolute w-1/4 right-0 bottom-00 md:bottom-08 rotate-15">
                        <div data-v-7a925bcb="" data-v-77d317c9="" class="popup" style="--size: 100%;">
                            <div data-v-7a925bcb=""
                                 class="absolute top-12 left-12 max-w-none w-[120%] h-[120%] -translate-x-12 -translate-y-12">
                                <!----></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bg-center-0 inset-0 rounded-full overflow-hidden transition-all duration-500 bg-center border-[5px] md:border-[14px] border-v-orange "
                                 :class="[isAmimate?'bg-100%':'bg-120%']"
                            ></div>
                            <div data-v-7a925bcb=""
                                 class="absolute bottom-0 left-0 w-full rounded-full overflow-hidden z-0 flex justify-center">
                                <img data-v-7a925bcb="" src="../assets/image/character-m-1-fc1a7590.png"
                                     class="transition-all duration-500 ease-elastic w-full" :class="[isAmimate?'translate-y-35':'translate-y-50']"><!---->
                            </div>
                            <div data-v-7a925bcb="" class="absolute inset-0 overflow-hidden translate-y-12">
                                <div data-v-7a925bcb=""
                                     class="rounded-full w-full h-full -translate-y-12 border-[5px] md:border-[14px] border-v-orange"></div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div data-v-77d317c9="" class="note md:hidden px-9">※各プランの詳細はお問い合わせください。<br data-v-77d317c9="">※料金やプランの内容は変更する可能性があります。-->
<!--                </div>-->
            </section>
            <section data-v-b6b535b3="" id="contact" v-show="(!isApp && type=='contact') || isApp" class="max-w-5xl mx-auto mt-20 md:mt-36 px-9">
                <div data-v-4d0737b2="" data-v-b6b535b3="" class="">
                    <h2 data-v-4d0737b2="">
                        <div data-v-4d0737b2=""
                             class="font-special font-semibold text-5xl md:text-7xl tracking-widest2 fade-slide-up is-inview"
                             data-scroll-class="0.5">CONTACT
                        </div>
                        <div data-v-4d0737b2="" class="text-4xl font-semibold tracking-widest mt-2 fadein is-inview"
                             data-scroll-class="0.5">联系
                        </div>
                    </h2>
                    <p data-v-4d0737b2="" class="my-12" style="font-weight:600">邮箱：service@djmetajoy.com</p>
                    <!--<div data-v-4d0737b2="" class="flex">
                        <button data-v-4d0737b2="" class="tab border-b-8 md:border-b-4">資料ダウンロード</button>
                        <button data-v-4d0737b2="" class="tab border-b">お問い合わせ</button>
                    </div>-->
<!--                    <p data-v-4d0737b2="" class="text-xs my-10">「＊」は入力必須項目になります。</p>-->
                    <div data-v-4d0737b2="">
                       <!-- <form data-v-4d0737b2="" novalidate="">
                            <div data-v-4d0737b2="" name="viro_name_gURP7DkcgEjqAx95_wrap" class="hidden"><input
                                data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください"
                                name="viro_name_gURP7DkcgEjqAx95"><input data-v-4d0737b2="" class="input"
                                                                         placeholder="こちらにご記入ください" name="valid_from">
                            </div>
                            <div data-v-4d0737b2="" class="form-field">
                                <div data-v-4d0737b2="" class="label">お名前＊<span data-v-4d0737b2=""
                                                                                class="error">&lt;!&ndash;&ndash;&gt;</span></div>
                                <input data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください" name="fullname"></div>
                            <div data-v-4d0737b2="" class="form-field">
                                <div data-v-4d0737b2="" class="label">会社名＊<span data-v-4d0737b2=""
                                                                                class="error">&lt;!&ndash;&ndash;&gt;</span></div>
                                <input data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください" name="company"></div>
                            <div data-v-4d0737b2="" class="form-field">
                                <div data-v-4d0737b2="" class="label">部署名＊<span data-v-4d0737b2=""
                                                                                class="error">&lt;!&ndash;&ndash;&gt;</span></div>
                                <input data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください" name="department">
                            </div>
                            <div data-v-4d0737b2="" class="form-field">
                                <div data-v-4d0737b2="" class="label">メールアドレス＊<span data-v-4d0737b2="" class="error">&lt;!&ndash;&ndash;&gt;</span>
                                </div>
                                <input data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください" name="email"></div>
                            <div data-v-4d0737b2="" class="form-field">
                                <div data-v-4d0737b2="" class="label">電話番号＊<span data-v-4d0737b2="" class="error">&lt;!&ndash;&ndash;&gt;</span>
                                </div>
                                <input data-v-4d0737b2="" class="input" placeholder="こちらにご記入ください" name="tel"></div>
                            &lt;!&ndash;&ndash;&gt;
                            <div data-v-4d0737b2="" class="text-center mt-20 bouncein is-inview"
                                 data-scroll-class="0.5">
                                <button data-v-4d0737b2=""
                                        class="relative bg-v-purple text-white font-semibold rounded-full py-4 px-14 md:px-16">
                                    資料をダウンロードする&lt;!&ndash;&ndash;&gt;</button>
                            </div>
                        </form>-->
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            show: false,
            isApp:false,
            isAmimate: false,
            isAmimate2: false,
            type:'keyvisual',
            colorType:'red',
            n:0,
            src:require("../assets/image/character-m-4-8779a946.png")
        }
    },
    // 滚动监听
    mounted () {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
        // 根据不同路由跳转不同页面
        if (this._isMobile()) {
            this.isApp = true
        } else {
            this.isApp = false
        }
    },
    methods:{
        // 判断是否是手机端，如果是，返回true
        _isMobile () {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        },
        changeShow(){
            this.show = !this.show
            this.isAmimate = false
            setTimeout(()=>{
                this.isAmimate = true
            },0)
        },
        // 获取页面滚动距离
        handleScroll () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop)
            if(scrollTop<110 && this.type=='about'){
                this.isAmimate = false
                setTimeout(()=>{
                    this.isAmimate = true
                },0)
            } else if(scrollTop>200 && scrollTop<700 && this.type=='about'){
                this.isAmimate2 = false
                setTimeout(()=>{
                    this.isAmimate2 = true
                },0)
            } else {
                this.isAmimate2 = false
                this.isAmimate = false
            }
            if(scrollTop>100 && this.type=='feature'){
                this.n++
                setTimeout(()=>{
                    this.isAmimate = true
                },0)
            }
            if(this.isApp){
                if(scrollTop>110 && scrollTop<900){
                    if(scrollTop>400 && scrollTop<900){
                        this.colorType = 'yellow'
                        this.src = require("../assets/image/character-f-2-3976119c.png")
                    } else {
                        this.src = require("../assets/image/character-m-2-c586d85b.png")
                        this.colorType = 'red'
                    }
                    this.isAmimate = false
                    setTimeout(()=>{
                        this.isAmimate = true
                    },0)
                } else if(scrollTop>940 && scrollTop<1496){
                    if(scrollTop>940 && scrollTop<1200){
                        this.colorType = 'yellow'
                        this.src = require("../assets/image/character-f-2-3976119c.png")
                    } else {
                        this.src=require("../assets/image/character-m-4-8779a946.png")
                        this.colorType = 'orange'
                    }
                    setTimeout(()=>{
                        this.isAmimate = false
                        this.isAmimate2 = false
                    },0)
                }else if(scrollTop>1496){
                    if(scrollTop>1496 && scrollTop<1800){
                        this.colorType = 'orange'
                        this.src=require("../assets/image/character-m-4-8779a946.png")
                    } else {
                        this.src = require("../assets/image/character-m-1-fc1a7590.png")
                        this.colorType = 'purple'
                    }
                    this.isAmimate = false
                    this.isAmimate2 = false
                    setTimeout(()=>{
                        this.isAmimate = true
                        this.isAmimate2 = true
                    },0)
                }
            }
        },
        onShow(type){
            this.type = type
            this.show = false
            this.isAmimate = false
            this.isAmimate2 = false
            this.n=0
            if(type=='about'){
                this.colorType = 'yellow'
                setTimeout(()=>{
                    this.isAmimate = true
                },0)
                this.src = require("../assets/image/character-f-2-3976119c.png")
            } else if(type=='feature') {
                this.colorType = 'orange'
                this.src = require("../assets/image/character-m-1-fc1a7590.png")
                setTimeout(()=>{
                    this.isAmimate = true
                },100)
            } else if(type=='contact') {
                this.src = require("../assets/image/character-m-2-c586d85b.png")
                this.colorType = 'purple'
            } else {
                this.src=require("../assets/image/character-m-4-8779a946.png")
                this.colorType = 'red'
            }
            if(!this.isApp){
                setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0
                },0)
            }

        }
    }
}
</script>
<style>
html, body {
    height: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/style3.css";
@import "../assets/css/style4.css";
.title{
    font-size: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0rem;
}
.footer-s{
    position: absolute;
    bottom: 0;
    z-index: 2;
    color: #fff;
    width: 100%;
    font-size: 12px;
    text-align: center;
}
.bg-center-0 {
    background-image: url('../assets/image/office-1-01efb15d.jpg');
}

h3 {
    margin: 40px 0 0;
}
li{
    text-align: left;
}
a {
    text-decoration: inherit;
    color: inherit;
}
a:hover{
    color: inherit;
}
.main-contents{
    z-index: 1;
}
    .my-10{
        text-algin:'left'
    }

</style>
